import React from 'react';
import { useRoutes } from 'react-router-dom';
import './App.css';
import routes from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import Register from './pages/Register';


const loading = (
  <div></div>
)


const App = () => {

    const routing = useRoutes(routes);
    console.log(routing)
   
    return (
      
        <React.Suspense fallback={loading}>
          {routing}
        </React.Suspense>
      
    );
}

export default App;
