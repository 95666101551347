import React from "react";

const Profile = React.lazy(() => import('./pages/Profile'));
const About = React.lazy(() => import('./pages/About'));
const Survey = React.lazy(() => import('./pages/Survey'));
const Thanks = React.lazy(() => import('./pages/Thanks'));
const Tutorial = React.lazy(() => import('./pages/Tutorial'));
const TheLayout = React.lazy(() => import('./pages/TheLayout'));
const Home = React.lazy(() => import('./pages/Home'));
const Register = React.lazy(() => import('./pages/Register'));
const ReduxExample = React.lazy(() => import('./pages/ReduxExample'));

const routes = [
    { path: '/', name: "Home", element: <Home/>, exact: true},
    {
        path: 'app',
        element: <TheLayout/>,
        children: [
            { path: 'profile/:id', name: "Profile", element: <Profile/>},
            { path: 'about', name: "About", element: <About/>},
            { path: 'survey', name: "Survey", element: <Survey/>},
            { path: 'thanks', name: "Thanks", element: <Thanks/>},
            { path: 'tutorial', name: "Tutorial", element: <Tutorial/>},
            { path: 'register', name: "Register", element: <Register/>},
            { path: 'reduxtest', name: "ReduxExample", element: <ReduxExample/>},
        ],
        exact: true
    }
]


export default routes;