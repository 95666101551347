import React from 'react';
import {ReactComponent as FMKLogo} from '../assets/images/FMK-Logo.svg';
import '../assets/css/header.css';

const Header = () =>{
    return(
        <div className='header-logo'>
            <a href="#" className='logo-reg'>
                <FMKLogo/>
            </a>
        </div>
    )
}

export default Header;