import React, { useState } from 'react';
import Cookies from 'universal-cookie';
import Axios from 'axios';
import {API} from '../ApiConfig';
import Header from '../components/Header.js'
import '../assets/css/template.css'
import '../assets/css/register.css'
import { useNavigate } from 'react-router-dom';

const cookies = new Cookies();

const Register = () => {
    //logic and code

    const navigate = useNavigate();
    // State management based off figma design
    // State management for the form
    const [form, setForm] = useState({from_melb: true, age_range: '0-10', gender: 'F' });

    
    const handleChange= (e) => {
        setForm(form => ({...form, [e.target.name]: e.target.value}));
    }
    
    const handleSubmit= (e) => {
        cookies.set("registered", "yes!", { path: '/' });
        // Math.random should be unique because of its seeding algorithm.
        // Convert it to base 36 (numbers + letters), and grab the first 9 characters
        // after the decimal.
        const uniqueRandomStr = '_' + Math.random().toString(36).substr(2, 9);
        console.log(uniqueRandomStr);
        cookies.set("cookie_id", uniqueRandomStr, { path: '/' });
        
        Axios.post(API + "/register", {...form, cookie_id: uniqueRandomStr})
        .then(res => {console.log("success"); navigate("/app/profile/1", { replace: true });})
        .catch(err=> {console.log(err)}
            );
      

        
        e.preventDefault();
    }
    // Cookie creation on submit or skip
    
    // Redux save to memory of UsrID

    return (
        // Copy design from Figma
        // Alter state of form when someone edits form
        <>

        <section id='register'>
            <div className='page-heading'>
                <h1>Get started</h1>
            </div>

            <div className='form'>
                <form className="registerStyle" onSubmit={handleSubmit}>
                    <div className="form-inner">
                        <h2>Are you from Melbourne?</h2>
                        <select name="from_melbourne" value={form.from_melbourne} onChange={handleChange}>
                            <option value="true">Yes</option>
                            <option value="false">No</option>
                        </select>
                        <h2>Age range</h2>
                        <select name="age_range"  value={form.age_range} onChange={handleChange}>
                            <option value="0-18">0-18</option>
                            <option value="19-24">19-24</option>
                            <option value="25-29">25-29</option>
                            <option value="30-39">30-39</option>
                            <option value="40-49">40-49</option>
                            <option value="50-59">50-59</option>
                            <option value="60+">60+</option>
                        </select>
                        <h2>Gender</h2>
                        <select name="gender" value={form.gender} onChange={handleChange}>
                            <option value="F">Female</option>
                            <option value="M">Male</option>
                            <option value="?">Other</option>
                        </select>
                    </div>

                    <a className="btn-submit-a" alt="go to building profiles">
                        <button type="submit" className="btn-submit">Submit</button>
                    </a>
                </form>
            </div>
        </section>
            
        </>
    )
}



export default Register